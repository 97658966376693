// App.js

import { Helmet } from "react-helmet";
import React, { useRef, useEffect } from "react";
import "./App.css";

function App() {
  const appRef = useRef(null);

  const toggleFullscreen = () => {
    const elem = appRef.current;

    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      elem.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable fullscreen: ${err.message}`);
      });
    }
  };

  useEffect(() => {
    const elem = appRef.current;

    const handleTap = () => {
      toggleFullscreen();
    };

    elem.addEventListener("click", handleTap);

    return () => {
      elem.removeEventListener("click", handleTap);
    };
  }, []);

  return (
    <div className="App" ref={appRef}>
      <Helmet>
        <title>Lovely moments</title>
      </Helmet>
      <header className="header">
        <h1>gearldine roche</h1>
      </header>
      <div id="heart"></div>
      <div className="container">
        <div className="header">
          <p>crafting lifetimes of love</p>
        </div>
        <p>
          As a seasoned wedding celebrant, Geraldine is dedicated to curating
          unforgettable moments.
        </p>
        <p>Performing ceremonies across New Zealand.</p>
      </div>
      <div className="contact">
        <a href="mailto:bram@boundingboxcreative.com">email to get in touch</a>
      </div>
    </div>
  );
}

export default App;
